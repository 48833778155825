import { Injectable } from '@angular/core';
import {HttpHelperService} from "./http-helper.service";


@Injectable()
export class AuthService {


  constructor(private httpHelper: HttpHelperService) {}



  secureLogin(formData: any){
    return this.httpHelper.post('authaccess', formData);
  }

  createRegistrationSession(formData: any) {
    return this.httpHelper.post('signup/registration/createRegistrationSession', formData);
  }

  verifyActivationCode(formData: any) {
    return this.httpHelper.post('signup/registration/verifyActivationCode', formData);
  }

  processRegistration(formData: any) {
    return this.httpHelper.post('signup/registration/processRegistration', formData);
  }


  routeToLogin(destination: string = 'nabonett'){
    console.log(destination);
    switch(destination){
      case 'vendor':
        window.location.href = 'https://vendor.styret.com/';
        break;
      case 'nabonett':
        window.location.href = 'https://minside.nabonett.no/';
        break;
      case 'keyfree':
        window.location.href = 'https://keyfree.styret.com/';
        break;
      default:
        window.location.href = 'https://minside.nabonett.no/';
        break;
    }
  }
}
