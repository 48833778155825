<div class="container h-100">
  <div class="row h-100 justify-content-center align-items-center">
    <form class="col-12 form-horizontal min-signup-form mb-55">
      <div class=" row">
        <div class="col-md-12 pb-3 min-heading-sec">
          <h3>Sjekk e-posten</h3>
        </div>
      </div>


      <div class=" row">
        <p class="col-md-12 mb-5 mt-4">Vi har sendt en e-post til {{mail}} med en lenke for å tilbakestille passordet ditt. Følg lenken for å aktivere kontoen din. Linken er kun gyldig i 1 time.

        </p>
      </div>
      <div class=" row">
        <div class="col-md-12 text-center">
          <a class="mt-5 link-blue" (click)="authService.routeToLogin(source)">Logg Inn</a>
        </div>

      </div>

    </form>
  </div>
</div>
<form class="form-horizontal"  >


</form>
