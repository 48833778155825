import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-success-account-creation',
  templateUrl: './success-account-creation.component.html',
  styleUrls: ['./success-account-creation.component.scss']
})
export class SuccessAccountCreationComponent implements OnInit {
  destination: string = 'nabonett';
  constructor(public authService: AuthService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    const queryParams: any = this.activatedRoute.snapshot.queryParams;
    if(queryParams.destination){
      this.destination = queryParams.destination;
    }
  }
}
