<div class="container h-100">
  <div class="row h-100 justify-content-center align-items-center">
    <form class="col-12 form-horizontal min-signup-form mb-55" [formGroup]="fg">
      <div class=" row">
        <div class="col-md-12 pb-3 min-heading-sec">
          <h3>Opprett bruker</h3>
          <p>Har du allerede bruker? <a (click)="authService.routeToLogin()" href="javascript:void(0)" >Logg inn</a></p>
        </div>
      </div>
      <div class=" row"  >
       <!-- <div class="col-md-12 pb-4">
          <label>Navn (fornavn og etternavn)<span class="required-star">*</span></label>
          <div class="input-group with-focus" >
            <input formControlName="name"
                   [ngClass]="{'is-invalid': wasValidated && ctrlName.dirty && ctrlName.invalid, 'is-valid': wasValidated && ctrlName.dirty && ctrlName.valid}"
                   placeholder="Kari Nordmann"  type="text" class="form-control">
          </div>
        </div>-->
        <div class="col-md-12 pb-4">
          <label>E-post<span class="required-star">*</span></label>
          <div class="input-group with-focus" >
            <input  formControlName="email"
                    [ngClass]="{'is-invalid': ctrlEmail.dirty && ctrlEmail.invalid, 'is-valid': ctrlEmail.dirty && ctrlEmail.valid}"
                    placeholder="eksempel@epost.no" type="text" class="form-control" >
          </div>
          <span *ngIf="ctrlEmail.dirty && ctrlEmail.invalid" class="error-wrapper flex-justify-right"><span class="error-list">Vennligst skriv inn en gyldig e-post.</span></span>
        </div>
      </div>
      <div class=" row">
       <p class="col-md-12 mb-5 mt-4">Du vil få tilsendt en epost med lenke som du kan bruke til å aktivere din konto. Denne er gyldig i 1 time.
       </p>
      </div>
      <div class=" row">
        <div class="col-md-12 text-center">
          <button (click)="onSignUpClick()" [disabled]="loadingData" [ngClass]="{'ajax-loader-2' : loadingData}"  mat-raised-button  class="btn-submit btn-yellow mb-5">Opprett bruker</button>
          <a class="mt-5 link-blue"  (click)="authService.routeToLogin()">Tilbake til innlogging</a>
        </div>
      </div>
    </form>
  </div>
</div>
