import { Component, OnInit } from '@angular/core';
import {FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors} from "@angular/forms";
import {CustomValidators} from "../../modules/shared/services/custom-validators";
import {PHONE_CODES_WITH_COUNTRYIDS, SharedService} from "../../modules/shared/services/shared.service";
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  requestSource: any = 'nabonett';
  phoneCodesWIthCountryId = PHONE_CODES_WITH_COUNTRYIDS;
  fg = new FormGroup({
    name: new FormControl(''),
    email: new FormControl('',[CustomValidators.customRequired, CustomValidators.customEmail]),
    phone: new FormControl('',[]),
    countryId: new FormControl(163,[]),
    source: new FormControl('',[])
  });

  get ctrlName(): FormControl {  return this.fg.controls['name'] as FormControl; }
  get ctrlEmail(): FormControl {  return this.fg.controls['email'] as FormControl; }
  get ctrlSource(): FormControl {  return this.fg.controls['source'] as FormControl; }
  constructor(public authService: AuthService,
              private sharedService: SharedService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    const hintVal  = this.activatedRoute.snapshot.queryParamMap.get('hint');
    if(hintVal){
      this.ctrlEmail.setValue(hintVal);
    }

    const source  = this.activatedRoute.snapshot.queryParamMap.get('source');
    if(source) {
      this.requestSource = source;
    }

    this.ctrlSource.setValue(this.requestSource);
  }


  wasValidated = false;
  loadingData = false;
  onNewPasswordClick(){
    if(this.fg.valid){
      const formVal = this.fg.value;
      this.loadingData = true;
      this.authService.createRegistrationSession(formVal).subscribe((response: any) => {
        console.log('CreateRegistrationSession', response);
        this.loadingData = false;
        this.router.navigate(['/reset-link-sent'],{ queryParams: {mail: this.ctrlEmail.value, source: this.requestSource}});
      }, error => {
        console.log('Error', error);
        this.loadingData = false;
      });
    }else{
      this.sharedService.validateAllFormFields(this.fg);
      this.sharedService.debugFormErrors(this.fg);
    }

    this.wasValidated = true;

  }
}
