import {Component, OnInit, ViewChild} from '@angular/core';
import {NgxSpinnerService} from "ngx-spinner";
import {ActivatedRoute, Route, Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";

import * as forge from 'node-forge'
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CustomValidators} from "../../modules/shared/services/custom-validators";
import {TooltipDirective} from "ngx-bootstrap/tooltip";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-process-verification',
  templateUrl: './process-verification.component.html',
  styleUrls: ['./process-verification.component.scss']
})
export class ProcessVerificationComponent implements OnInit {
  @ViewChild('REF_TOOPTIP_ELEM') tooltipAnchor?: TooltipDirective;
  sessionId: any;
  verifiedEmail: any;
  regdKey: any;
  destination: any = 'nabonett';
  fg = new FormGroup({
    password: new FormControl('', [CustomValidators.customRequired, Validators.minLength(8), CustomValidators.validPassword]),
    retypePassword: new FormControl('', [CustomValidators.customRequired]),
  })

  get ctrlPassword(): FormControl {
    return this.fg.controls['password'] as FormControl;
  }

  get ctrlRetypePassword(): FormControl {
    return this.fg.controls['retypePassword'] as FormControl;
  }

  wasValidated = false;
  inProgress = false;

  constructor(private spinner: NgxSpinnerService,
              private activatedRoute: ActivatedRoute,
              public authService: AuthService,
              private router: Router,
              public cValidator: CustomValidators,
              private toastr: ToastrService
  ) {
    this.spinner.show();
  }

  ngOnInit(): void {
    const queryParams: any = this.activatedRoute.snapshot.queryParams;
    // ----
    // this.spinner.hide();
    // this.verifiedEmail = true;
    this.processVerificationData(queryParams);
  }

  // STEP - 1
  processVerificationData(params: any) {
    if (params.session && params.data) {
      this.sessionId = params.session;
      this.destination = params.source;
      let refindData = params.data.replace(/ /g, "+");
      const data: any = {
        sessionId: params.session,
        activationCode: refindData
      }
      this.authService.verifyActivationCode(data).subscribe((response: any) => {
        if (response.success) {
          this.verifiedEmail = response.email;
          this.regdKey = response.registrationKey;
          this.spinner.hide();
        } else {
          this.redirectToInvalidLink();
        }
      }, error => {
        this.redirectToInvalidLink();
      })
    } else {
      this.redirectToInvalidLink();
    }
  }

  // STEP - 2
  onConfirmPasswordClick() {
    this.wasValidated = true;
    if (this.isFormValid()) {
      try{
        const rsa = forge.pki.publicKeyFromPem(this.regdKey);
        const regdKey = window.btoa(rsa.encrypt(this.ctrlPassword.value.toString()));

        const regdData = {
          sessionId: this.sessionId,
          userData: regdKey
        }
        this.inProgress = true;
        this.authService.processRegistration(regdData).subscribe(response => {
          this.inProgress = false;
          console.log('processRegistration', response);
          this.router.navigate(['account-created'], { queryParams: {destination: this.destination}})
        }, error => {
          this.inProgress = false;
          console.log('error - processRegistration', error);
          this.toastr.error('Ugyldig økt oppdaget. Vennligst prøv på nytt.', 'Error');
        })
      }catch (e) {
        this.toastr.error('Det har oppstått en feil under forsøk på å behandle forespørselen din. Vennligst kontakt admin.', 'Error');
      }

    }

  }

  triggerTooltip(){
    if(this.avoidTooltipActions || !this.tooltipAnchor){
      return false
    }
    if(this.ctrlPassword.dirty && this.ctrlPassword.invalid){
      this.tooltipAnchor.show();
    } else {
      this.tooltipAnchor.hide();
    }
    return true;
  }

  avoidTooltipActions = false;
  onTooltipClick(){
    if(this.avoidTooltipActions){
      this.avoidTooltipActions = false;
      this.tooltipAnchor?.hide();
    }else{
      this.avoidTooltipActions = true;
      this.tooltipAnchor?.show();
    }
  }


  isFormValid() {
    this.triggerTooltip();

    return this.fg.valid && this.ctrlPassword.value == this.ctrlRetypePassword.value ? true : false;
  }

  redirectToInvalidLink() {
    this.router.navigate(['invalid-link']);
  }

  viewPswd = false;
  onViewPassword($evt: any, isEndEvt?: any){
    this.viewPswd = isEndEvt? false : true
    console.log(this.viewPswd, $evt);
  }

  isValidRetypePswd(){
    let isValid = false;
    if(this.ctrlRetypePassword.valid && this.ctrlRetypePassword.value == this.ctrlPassword.value){
      isValid = true;
    }
    return isValid;
  }
}
